<template>
  <app-layout>
    <div class="my-5 rounded-lg flex items-center justify-center">
      <div
        class="w-11/12 mx-auto md:mx-0 p-6 bg-white bg-opacity-80 shadow-xl md:w-6/12 xl:w-5/12"
      >
        <h1
          class="font-bold text-lg text-green-1002 text-center bold md:text-2xl"
        >
          <br />
          VERIFIQUE SE VOCÊ ESTÁ PARTICIPANDO DA COLHEITA
        </h1>
        <form @submit.prevent="submit">
          <template v-if="statusUser == ''">
            <br /><br />
            <input
              type="text"
              name="cpf"
              id="cpf"
              placeholder="CPF"
              class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-6 text-lg md:text-2xl focus:outline-none"
              v-model="cpf"
              v-mask="'###.###.###-##'"
              autocomplete="off"
            />
            <br />
            <button
              type="button"
              v-on:click="getValidationUser"
              class="py-1 bg-green-1002 text-white text-center w-full mt-5 text-lg md:text-2xl focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
              :disabled="submitting"
            >
              {{ buttonText }}
            </button>
          </template>
          <template v-if="statusUser == 'ok'">
            <select
              v-model="curso"
              id="curso"
              name="curso"
              class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-6 text-lg md:text-xl focus:outline-none"
            >
              <option value="" disabled selected
                >Qual curso você realizou no SENAR-MT</option
              >
              <option
                v-for="(course, idx) in courses"
                :key="idx"
                :value="course.id"
              >
                {{ course.name }}</option
              >
            </select>

            <label class="text-green-1002 bold" for="birthDate"
              >Informe sua data de nascimento:</label
            >
            <input
              type="date"
              name="birthDate"
              placeholder="Informe a sua  data de aniversário"
              id="birthDate"
              class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-5 text-lg md:text-xl focus:outline-none"
              v-model="birthDate"
              autoComplete="off"
            />
            <p class="text-green-1002 bold text-center">OU</p>
            <label class="text-green-1002 bold" for="startCourseDate"
              >Informe a data de início do curso:</label
            >
            <input
              type="date"
              name="startCourseDate"
              placeholder="Informe a data de início do curso"
              id="startCourseDate"
              class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-5 text-lg md:text-xl focus:outline-none"
              v-model="startCourseDate"
              autoComplete="off"
            />

            <p class="text-sm text-justify px-2 mt-2 md:text-lg">
              Coloque no campo acima a data de início do curso selecionado no
              campo "Qual curso você realizou no SENAR-MT", encontrado na parte
              inferior do seu certificado.
              <a
                href="https://3554294.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=425&deploy=1&compid=3554294&h=200ca18be0de1b3be41e&opcao=principal&uf=9229037571&option=print"
                class="text-green-1002 font-bold underline"
                target="_blank"
                >Caso seu certificado não tenha ou você não esteja encontrando,
                imprima seu certificado através deste link.</a
              >
            </p>

            <button
              type="submit"
              class="py-1 bg-green-1002 text-white text-center w-full mt-5 text-lg md:text-2xl focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
              :disabled="submitting"
            >
              {{ buttonText }}
            </button>
          </template>
        </form>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/AppLayout';
import { mask } from 'vue-the-mask';
import axios from '@/utils/axios';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Register',

  title() {
    return `${process.env.VUE_APP_NAME} | Quero Participar`;
  },

  components: {
    AppLayout
  },

  directives: { mask },

  data() {
    return {
      submitting: false,
      cpf: '',
      startCourseDate: '',
      birthDate: '',
      curso: '',
      courses: [],
      statusUser: ''
    };
  },

  computed: {
    ...mapGetters('register', {
      status: 'status',
      statusParticipant: 'statusParticipant',
      participant: 'participant'
    }),

    buttonText() {
      return this.submitting ? 'VERIFICANDO...' : 'VERIFICAR';
    }
  },

  methods: {
    ...mapActions('register', {
      register: 'register',
      registerByCpf: 'registerByCpf'
    }),

    getCourses() {
      axios
        .get('api/registrar/get/course/' + this.participant)
        .then(response => {
          this.courses = [...response.data.data];
        })
        .catch(response => {
          let errors = response.data.errors;

          Object.values(errors).forEach(error => {
            this.$toast.error(...error);
          });
          this.$router.push({ name: 'register' });
        });
    },

    getValidationUser: function(event) {
      if (event) {
        this.submitting = true;

        this.registerByCpf({
          cpf: this.cpf
        })
          .then(() => {
            if (!parseInt(this.statusParticipant)) {
              this.getCourses();
              this.statusUser = 'ok';
            } else {
              this.$toast.warning('Esse usuário já foi ativado.');

              this.$router.push({ name: 'login' });
            }
          })
          .catch(({ response }) => {
            let errors = response.data.errors;
            if (!errors) {
              this.$toast.warning(
                'Usuário não localizado, procure o Sindicato Rural  da sua Cidade.'
              );
            }
            Object.values(errors).forEach(error => {
              this.$toast.error(...error);
            });
          })
          .finally(() => (this.submitting = false));
      }
    },
    submit() {
      this.submitting = true;
      this.register({
        participant: this.participant,
        startCourseDate: this.startCourseDate,
        birthDate: this.birthDate,
        curso: this.curso
      })
        .then(() => {
          if (!parseInt(this.status)) {
            this.$router.push({ name: 'register-confirmation' });
          } else {
            this.$toast.warning('Esse usuário já foi ativado.');

            this.$router.push({ name: 'login' });
          }
        })
        .catch(({ response }) => {
          let errors = response.data.errors;

          if (!errors) {
            this.$toast.warning(
              'Dados informados não conferem com o participante.'
            );
          }

          Object.values(errors).forEach(error => {
            this.$toast.error(...error);
          });
        })
        .finally(() => (this.submitting = false));
    }
  }
};
</script>

<style scoped>
input {
  margin-top: 0px;
}
select {
  margin-bottom: 20px;
}
p {
  padding: 10px;
}
</style>
