<template>
  <div :style="{
    backgroundImage: `url(${this.backgroundImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom'
  }" class="flex flex-col min-h-screen bg-fixed" :class="isOpen ? 'overflow-hidden' : ''">
    <header class="flex justify-between w-full items-center py-4 md:hidden">
      <button class="p-4 focus:outline-none" @click.prevent="isOpen = true">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" fill="rgba(0,122,111,1)" />
        </svg>
      </button>
      <nav v-if="isOpen" class="flex flex-col justify-between absolute inset-0 bg-white z-10">
        <header class="flex justify-between w-full items-center py-4">
          <button class="p-4 focus:outline-none" @click.prevent="isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                fill="rgba(0,122,111,1)" />
            </svg>
          </button>

          <router-link class="flex" :to="{ name: 'home' }">
            <img class="w-36" :src="require('../assets/images/logo.png')" alt="Logotipo Colheita de Talentos" />
          </router-link>

          <button class="p-4 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4-5 4v-3H2v-2h8z"
                fill="rgba(0,122,111,1)" />
            </svg>
          </button>
        </header>

        <ul class="mx-auto sm:flex">
          <li class="flex py-4 sm:py-2 sm:mr-4">
            <router-link class="text-2xl text-center m-auto cursor-pointer font-bold text-green-1002"
              :to="{ name: 'about' }">
              Sobre
            </router-link>
          </li>
          <li class="flex py-4 sm:py-2">
            <router-link class="text-2xl text-center m-auto cursor-pointer font-bold text-green-1002"
              :to="{ name: 'how-it-works' }">
              Como Funciona
            </router-link>
          </li>
          <li class="flex py-4 sm:py-2">
            <router-link class="text-2xl text-center m-auto cursor-pointer font-bold text-green-1002"
              :to="{ name: 'how-it-works' }">
              Para empresas
            </router-link>
          </li>
        </ul>
        <ul class="mx-auto sm:flex">
          <li class="mb-4">
            <button
              class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none mr-5"
              onclick="window.open('https://apps.apple.com/us/app/colheita-de-talentos/id6444526824', '_blank')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11.624 7.222c-.876 0-2.232-.996-3.66-.96-1.884.024-3.612 1.092-4.584 2.784-1.956 3.396-.504 8.412 1.404 11.172.936 1.344 2.04 2.856 3.504 2.808 1.404-.06 1.932-.912 3.636-.912 1.692 0 2.172.912 3.66.876 1.512-.024 2.472-1.368 3.396-2.724 1.068-1.56 1.512-3.072 1.536-3.156-.036-.012-2.94-1.128-2.976-4.488-.024-2.808 2.292-4.152 2.4-4.212-1.32-1.932-3.348-2.148-4.056-2.196-1.848-.144-3.396 1.008-4.26 1.008zm3.12-2.832c.78-.936 1.296-2.244 1.152-3.54-1.116.048-2.46.744-3.264 1.68-.72.828-1.344 2.16-1.176 3.432 1.236.096 2.508-.636 3.288-1.572z" />
              </svg>
              <span class="ml-4 flex items-start flex-col leading-none">
                <span class="text-xs text-gray-600 mb-1">Baixe nosso aplicativo</span>
                <span class="title-font font-medium">App Store</span>
              </span>
            </button>
          </li>
          <li>
            <button
              class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none"
              onclick="window.open('https://play.google.com/store/apps/details?id=com.colheitataleto&hl=pt_BR&gl=US', '_blank')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6" viewBox="0 0 512 512">
                <path
                  d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z">
                </path>
              </svg>
              <span class="ml-4 flex items-start flex-col leading-none">
                <span class="text-xs text-gray-600 mb-1">Baixe nosso aplicativo</span>
                <span class="title-font font-medium">Google Play</span>
              </span>
            </button>
          </li>
        </ul>

        <footer class="pt-4 sm:pt-2">
          <h2 class="text-center font-bold text-green-1002 text-xl mb-4 sm:mb-2">
            Uma realização:
          </h2>

          <img class="w-64 mx-auto mb-4" :src="require('../assets/images/logo-sistema-famato.png')"
            alt="Logotipo Sistema Famato" />

          <p class="border-t border-green-1002 text-xs text-center text-green-1002 font-bold py-2">
            © SISTEMA FAMATO 2021 - Todos os direitos reservados.
          </p>
        </footer>
      </nav>

      <router-link class="flex" :to="{ name: 'home' }">
        <img class="w-36" :src="require('../assets/images/logo.png')" alt="Logotipo Colheita de Talentos" />
      </router-link>

      <router-link class="flex p-4 focus:outline-none" :to="{ name: 'login' }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4-5 4v-3H2v-2h8z"
            fill="rgba(0,122,111,1)" />
        </svg>
      </router-link>
    </header>
    <header class="hidden md:flex justify-between w-full items-center py-4 bg-white bg-opacity-90">
      <router-link class="flex" :to="{ name: 'home' }">
        <img class="w-56 mx-8 my-4" :src="require('../assets/images/logo.png')" alt="Logotipo Colheita de Talentos" />
      </router-link>

      <nav>
        <ul class="mx-auto sm:flex">
          <li class="flex py-4 sm:mr-4">
            <router-link
              class="text-2xl text-center m-auto cursor-pointer font-bold text-green-1002 hover:text-green-1003"
              :to="{ name: 'about' }">
              Sobre
            </router-link>
          </li>
          <li class="flex py-4">
            <router-link
              class="text-2xl text-center m-auto cursor-pointer font-bold text-green-1002 hover:text-green-1003"
              :to="{ name: 'how-it-works' }">
              Como Funciona
            </router-link>
          </li>
          <li class="flex py-4 sm:ml-4">
            <router-link class="text-2xl text-center m-auto cursor-pointer font-bold text-green-1002"
              :to="{ name: 'validate-access' }">
              Para empresas
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="flex">
        <button
          class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none mr-5"
          onclick="window.open('https://apps.apple.com/us/app/colheita-de-talentos/id6444526824', '_blank')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M11.624 7.222c-.876 0-2.232-.996-3.66-.96-1.884.024-3.612 1.092-4.584 2.784-1.956 3.396-.504 8.412 1.404 11.172.936 1.344 2.04 2.856 3.504 2.808 1.404-.06 1.932-.912 3.636-.912 1.692 0 2.172.912 3.66.876 1.512-.024 2.472-1.368 3.396-2.724 1.068-1.56 1.512-3.072 1.536-3.156-.036-.012-2.94-1.128-2.976-4.488-.024-2.808 2.292-4.152 2.4-4.212-1.32-1.932-3.348-2.148-4.056-2.196-1.848-.144-3.396 1.008-4.26 1.008zm3.12-2.832c.78-.936 1.296-2.244 1.152-3.54-1.116.048-2.46.744-3.264 1.68-.72.828-1.344 2.16-1.176 3.432 1.236.096 2.508-.636 3.288-1.572z" />
          </svg>
          <span class="ml-4 flex items-start flex-col leading-none">
            <span class="text-xs text-gray-600 mb-1">Baixe nosso aplicativo</span>
            <span class="title-font font-medium">App Store</span>
          </span>
        </button>
        <button class="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none"
          onclick="window.open('https://play.google.com/store/apps/details?id=com.colheitataleto&hl=pt_BR&gl=US', '_blank')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6" viewBox="0 0 512 512">
            <path
              d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z">
            </path>
          </svg>
          <span class="ml-4 flex items-start flex-col leading-none">
            <span class="text-xs text-gray-600 mb-1">Baixe nosso aplicativo</span>
            <span class="title-font font-medium">Google Play</span>
          </span>
        </button>
        <router-link
          class="text-2xl text-center mx-8 my-4 cursor-pointer font-bold text-white bg-green-1002 px-5 py-1 rounded-full"
          :to="{ name: 'login' }">
          Acessar
        </router-link>
      </div>
    </header>

    <slot></slot>

    <doubts></doubts>
    <whatsapp-link></whatsapp-link>
  </div>
</template>

<script>
// import WhatsappLink from '../components/WhatsappLink';
import doubts from '../components/doubts';
import { mapGetters } from 'vuex';
export default {
  name: 'AppLayout',
  components: {
    // WhatsappLink, 
    doubts
  },
  data() {
    return {
      isOpen: false,
      backgroundImage: ''
    };
  },

  computed: {
    ...mapGetters('auth', {
      authenticated: 'authenticated',
      permissions: 'permissions'
    }),

    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    isActive(route) {
      return this.$route.name === route
        ? 'text-green-1003'
        : 'text-green-1002 hover:text-green-1003';
    },

    redirect() {
      if (this.user.role.name === 'participant') {
        this.$router.push({
          name: 'dashboard'
        });
      } else {
        this.$router.push({
          name: 'search'
        });
      }
    }
  },

  watch: {
    $route: {
      handler() {
        this.backgroundImage = require(`../assets/images/background-login.png`);

        if (
          this.$route.name !== 'register' &&
          this.$route.name !== 'register-confirmation' &&
          this.$route.name !== 'recovery' &&
          this.$route.name !== 'reset' &&
          this.$route.name !== 'validate-access' &&
          this.$route.name !== 'create-access' &&
          this.$route.name !== 'doubts' &&
          this.$route.name !== 'activate'
        ) {
          this.backgroundImage = require(`../assets/images/background-${this.$route.name}.png`);
        }

        if (this.$route.name === 'create-access') {
          this.backgroundImage = require(`../assets/images/img-como.png`);
        }

        if (this.$route.name === 'validate-access') {
          this.backgroundImage = require(`../assets/images/rodape.png`);
        }

        if (this.$route.name === 'doubts' || this.$route.name === 'activate') {
          this.backgroundImage = require(`../assets/images/rodape.png`);
        }
      },
      immediate: true
    }
  },

  created() {
    this.redirect();
  }
};
</script>

<style scoped>
* {
  font-family: 'gadugi', sans-serif;
}

.bold {
  font-family: 'gadugib', sans-serif;
}
</style>
